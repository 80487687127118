import {Controller} from "@hotwired/stimulus"
import {patch} from "@rails/request.js"

export default class extends Controller {
    static values = { chosen: String }

    choose() {

        Array.from(document.querySelectorAll('.circle.active')).forEach(function(el) {
            el.classList.remove('active');
        });

        document.getElementById(this.chosenValue).querySelector('.circle').classList.add('active')

    } // choose

    send() {

        let chosen_end = Array.from(document.querySelectorAll('.circle.active'))

        if (chosen_end.length > 0) {
            chosen_end.forEach(function(el) {
                el.parentElement.id;

                patch("/bananas/1", {
                    body: {chosen: el.parentElement.id},
                    responseKind: "turbo-stream"
                }).then(function(response) {

                })

            });
        }
    } // send

    buttonHover() {
        setTimeout(function () {
            document.getElementById('button-send').src='assets/frontend/button-send-hover.svg'
        },500);


    } //

    buttonNormal() {
        document.getElementById('button-send').src='assets/frontend/button-send.svg'
    } // buttonHover

}
